// components/Layout.js

import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  return (
    <div>
 
      <main>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
